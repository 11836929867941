.cursor-pointer {
  cursor: pointer;
}

.full-screen {
  width: 100%;
  height: 100%;
}

/******Z INDEX CLASSES*********/ //should be there or not Jan opinion

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}
/******END Z INDEX CLASSES*********/

.content-no-click {
  pointer-events: none;
}

.display-flex {
  display: flex;
}
.btn-min-width {
  min-width: 250px;
}

.max-width-500 {
  max-width: 500px;
}

.margin-0-auto {
  margin: 0 auto;
}

.padding-bottom-none {
  padding-bottom: 0px;
}

.margin-0 {
  margin: 0;
}
