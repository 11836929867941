/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */
/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';
/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';
/******************Spacing Variables**************************/
:root {
  --space-2xs: 3px;
  --space-xs: 6px;
  --space-s: 9px;
  --space-m: 12px;
  --space-l: 15px;
  --space-xl: 18px;
  --space-2xl: 21px;
  --space-3xl: 24px;
  --space-4xl: 48px;
  --space-5xl: 60px;
}

/***********************End Spacing Variables********************/
/******************Colors**************************/
:root {
  /*uoa blues*/
  --color-primary: #00467f;
  --color-primary-rgb: 0, 70, 127;
  --color-primary-tint: #0062b2;
  --color-secondary: #009ac7;
  --color-secondary-rgb: 0, 154, 199;
  --color-secondary-tint: #00a9db;
  --color-tertiary: #8d9091;
  --color-tertiary-rgb: 141, 144, 145;
  --color-tertiary-tint: #999999;
  /*greys*/
  /*light*/
  --color-light: #ffffff;
  --color-light-rgb: 255, 255, 255;
  --color-light-1: #f2f2f2;
  --color-light-1-rgb: 242, 242, 242;
  --color-light-2: #bec3c4;
  /*medium*/
  --color-medium: #d9d9d9;
  --color-medium-rgb: 217, 217, 217;
  /*dark*/
  --color-dark: #000000;
  --color-dark-rgb: 0, 0, 0;
  --color-dark-1: #4a4a4c;
  --color-dark-1-rgb: 74, 74, 76;
  --color-dark-2: #404040;
  --color-dark-2-rgb: 64, 64, 64;
  --color-dark-2-tint: #9f9f9f;
  --color-dark-3: #333333;
  --color-dark-3-rgb: 51, 51, 51;
  --color-dark-3-tint: #747778;
  /* success */
  --color-success: #2ec95c;
  --color-success-rgb: 42, 201, 92;
  /* warning */
  --color-warning: #fdd835;
  --color-warning-rgb: 253, 216, 52;
  /*error*/
  --color-error: #ed0c0c;
  --color-error-rgb: 237, 12, 12;
  --color-error-toast: #f53d3d;
}

/***********************End Colors********************/
.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-tertiary {
  color: var(--color-tertiary);
}

.color-dark {
  color: var(--color-dark);
}

.color-dark-1 {
  color: var(--color-dark-1);
}

.color-dark-2 {
  color: var(--color-dark-2);
}

.color-dark-3 {
  color: var(--color-dark-3);
}

.color-dark-3-tint {
  color: var(--color-dark-3-tint);
}

.color-medium {
  color: var(--color-medium);
}

.color-light {
  color: var(--color-light);
}

.color-light-1 {
  color: var(--color-light-1);
}

.color-light-2 {
  color: var(--color-light-2);
}

.color-error {
  color: var(--color-error);
}

.color-success {
  color: var(--color-success);
}

.color-warning {
  color: var(--color-warning);
}

.border-primary {
  border: 1px solid var(--color-primary);
}

.border-top-primary {
  border-top: 1px solid var(--color-primary);
}

.border-right-primary {
  border-bottom: 1px solid var(--color-primary);
}

.border-bottom-primary {
  border-bottom: 1px solid var(--color-primary);
}

.border-left-primary {
  border-left: 1px solid var(--color-primary);
}

.border-light-2 {
  border: 1px solid var(--color-light-2);
}

.border-top-light-2 {
  border-top: 1px solid var(--color-light-2);
}

.border-right-light-2 {
  border-right: 1px solid var(--color-light-2);
}

.border-bottom-light-2 {
  border-bottom: 1px solid var(--color-light-2);
}

.border-left-light-2 {
  border-left: 1px solid var(--color-light-2);
}

.border-error {
  border: 2px solid var(--color-error);
}

.border-bottom-error {
  border-bottom: 2px solid var(--color-error);
}

.background-primary {
  background-color: var(--color-primary);
}

.background-secondary {
  background-color: var(--color-secondary);
}

.background-tertiary {
  background-color: var(--color-tertiary);
}

.background-dark-1 {
  background-color: var(--color-dark-1);
}

.background-dark-2 {
  background-color: var(--color-dark-2);
}

.background-dark-3 {
  background-color: var(--color-dark-3);
}

.background-dark {
  background-color: var(--color-dark);
}

.background-medium {
  background-color: var(--color-medium);
}

.background-light {
  background-color: var(--color-light);
}

.background-light-1 {
  background-color: var(--color-light-1);
}

.background-light-2 {
  background-color: var(--color-light-2);
}

@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 500;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 600;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 700;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Bold.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: bold;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BoldItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Book.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Book.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-Book.woff") format("woff");
  /* Pretty Modern Browsers */
}
@font-face {
  font-family: "National";
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BookItalic.eot");
  /* IE9 Compat Modes */
  src: url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BookItalic.woff2") format("woff2"), url("https://cdn.auckland.ac.nz/designs/uoa-digital/clientlibs/css/base/fonts/NationalWeb-BookItalic.woff") format("woff");
  /* Pretty Modern Browsers */
  font-style: italic;
}
html,
body,
span,
button,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ion-item,
ion-title {
  font-family: "National" !important;
  white-space: normal;
}

.bold {
  font-weight: bold;
}

.not-bold {
  font-weight: 100;
}

.italic {
  font-style: italic;
}

.crossed {
  text-decoration: line-through;
}

.font-s {
  font-size: 0.89rem;
}

.font-default, .card-content-md p, .card-content-ios p, .card-content-md, .card-content-ios, .sc-ion-card-md-h, ion-button {
  font-size: 1rem;
}

.font-l {
  font-size: 1.25rem;
}

.font-xl {
  font-size: 1.5rem !important;
}

.font-xxl {
  font-size: 2rem !important;
}

.cursor-pointer {
  cursor: pointer;
}

.full-screen {
  width: 100%;
  height: 100%;
}

/******Z INDEX CLASSES*********/
.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-4 {
  z-index: 4;
}

.z-index-5 {
  z-index: 5;
}

/******END Z INDEX CLASSES*********/
.content-no-click {
  pointer-events: none;
}

.display-flex {
  display: flex;
}

.btn-min-width {
  min-width: 250px;
}

.max-width-500 {
  max-width: 500px;
}

.margin-0-auto {
  margin: 0 auto;
}

.padding-bottom-none {
  padding-bottom: 0px;
}

.margin-0 {
  margin: 0;
}

/*********Ionic 4 Fixes**********/
ion-button {
  text-transform: none;
  letter-spacing: normal;
  white-space: normal;
}
ion-button ion-icon {
  min-width: 26px;
}
ion-button span {
  padding-bottom: var(--space-xs);
  text-align: initial;
}

.sc-ion-card-md-h {
  --color: (--color-dark-1);
}

ion-card-header + .card-content-md {
  padding-top: var(--space-s);
  padding-bottom: var(--space-s);
}

.alert-tappable.alert-checkbox,
.alert-tappable.alert-radio {
  height: auto;
  contain: content;
}

.alert-checkbox-label.sc-ion-alert-md,
.alert-checkbox-label.sc-ion-alert-ios,
.alert-radio-label.sc-ion-alert-md,
.alert-radio-label.sc-ion-alert-ios {
  white-space: normal;
  padding: 10px 26px;
}

.alert-radio-icon.sc-ion-alert-md,
.alert-checkbox-icon.sc-ion-alert-md {
  left: 13px;
}

.in-item {
  max-width: 100%;
}

.item.sc-ion-label-md-h,
.item .sc-ion-label-md-h {
  --color: var(--color-primary);
}

.sc-ion-card-md-h,
.alert-wrapper.sc-ion-alert-md {
  border-radius: 0;
}

@media only screen and (min-height: 768px) and (min-width: 768px) {
  .sc-ion-modal-md-h {
    --width: 540px;
    --height: 500px;
  }
}
.swiper-pagination-bullet {
  opacity: 1;
}

.card-content-md h2, .card-content-ios h2 {
  font-size: 1.25rem;
  font-weight: bold;
}
.card-content-md h3, .card-content-ios h3 {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: inherit;
}
/*********End Ionic 4 Fixes**********/
.margin-top-0 {
  margin-top: 0;
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-s {
  margin-top: var(--space-s);
}

.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-m {
  margin-top: var(--space-m);
}

.margin-top-l {
  margin-top: var(--space-l);
}

.margin-right-xs {
  margin-left: var(--space-xs);
}

.margin-right-s {
  margin-right: var(--space-s);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-m {
  margin-right: var(--space-m);
}

.margin-right-l {
  margin-right: var(--space-l);
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-s {
  margin-bottom: var(--space-s);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-m {
  margin-bottom: var(--space-m);
}

.margin-bottom-l {
  margin-bottom: var(--space-l);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-s {
  margin-left: var(--space-s);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-m {
  margin-left: var(--space-m);
}

.margin-left-l {
  margin-left: var(--space-l);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-s {
  padding-top: var(--space-s);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-m {
  padding-top: var(--space-m);
}

.padding-top-l {
  padding-top: var(--space-l);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-s {
  padding-right: var(--space-s);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-m {
  padding-right: var(--space-m);
}

.padding-right-l {
  padding-right: var(--space-l);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-s {
  padding-bottom: var(--space-s);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-m {
  padding-bottom: var(--space-m);
}

.padding-bottom-l {
  padding-bottom: var(--space-l);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-s {
  padding-left: var(--space-s);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-m {
  padding-left: var(--space-m);
}

.padding-left-l {
  padding-left: var(--space-l);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-s {
  padding: var(--space-s);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-m {
  padding: var(--space-m);
}

.padding-l {
  padding: var(--space-l);
}

.content-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.inner-content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 var(--space-m) var(--space-4xl) var(--space-m);
}

:root {
  font-size: 1.125rem !important;
}

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.toast-error {
  font-size: 18px;
}

#searchbar .searchbar-input {
  border: 1px solid var(--color-light-2);
  font-size: 20px;
  color: var(--color-primary);
  --webkit-box-shadow: none !important;
  box-shadow: none !important;
}
#searchbar .searchbar-search-icon {
  top: 12px !important;
}

.loading-spinner {
  --spinner-color: grey;
}

.error-modal {
  --height: 180px;
  --border-radius: 4px;
}
.error-modal .modal-wrapper {
  width: 50vw;
}
.error-modal .modal-wrapper .ion-page {
  position: relative;
  contain: content;
  max-height: 90vh;
}
.error-modal .modal-wrapper .ion-page .modal-content {
  overflow: auto;
}

.native-input[disabled].sc-ion-input-md {
  opacity: 1;
}