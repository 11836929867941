.margin-top-0 {
  margin-top: 0;
}

.margin-top-xs {
  margin-top: var(--space-xs);
}

.margin-top-s {
  margin-top: var(--space-s);
}
.margin-top-sm {
  margin-top: var(--space-sm);
}

.margin-top-m {
  margin-top: var(--space-m);
}

.margin-top-l {
  margin-top: var(--space-l);
}

.margin-right-xs {
  margin-left: var(--space-xs);
}

.margin-right-s {
  margin-right: var(--space-s);
}

.margin-right-sm {
  margin-right: var(--space-sm);
}

.margin-right-m {
  margin-right: var(--space-m);
}

.margin-right-l {
  margin-right: var(--space-l);
}

.margin-bottom-0 {
  margin-bottom: 0;
}

.margin-bottom-xs {
  margin-bottom: var(--space-xs);
}

.margin-bottom-s {
  margin-bottom: var(--space-s);
}

.margin-bottom-sm {
  margin-bottom: var(--space-sm);
}

.margin-bottom-m {
  margin-bottom: var(--space-m);
}

.margin-bottom-l {
  margin-bottom: var(--space-l);
}

.margin-left-xs {
  margin-left: var(--space-xs);
}

.margin-left-s {
  margin-left: var(--space-s);
}

.margin-left-sm {
  margin-left: var(--space-sm);
}

.margin-left-m {
  margin-left: var(--space-m);
}

.margin-left-l {
  margin-left: var(--space-l);
}

.padding-top-xs {
  padding-top: var(--space-xs);
}

.padding-top-s {
  padding-top: var(--space-s);
}

.padding-top-sm {
  padding-top: var(--space-sm);
}

.padding-top-m {
  padding-top: var(--space-m);
}

.padding-top-l {
  padding-top: var(--space-l);
}

.padding-right-xs {
  padding-right: var(--space-xs);
}

.padding-right-s {
  padding-right: var(--space-s);
}

.padding-right-sm {
  padding-right: var(--space-sm);
}

.padding-right-m {
  padding-right: var(--space-m);
}

.padding-right-l {
  padding-right: var(--space-l);
}

.padding-bottom-xs {
  padding-bottom: var(--space-xs);
}

.padding-bottom-s {
  padding-bottom: var(--space-s);
}

.padding-bottom-sm {
  padding-bottom: var(--space-sm);
}

.padding-bottom-m {
  padding-bottom: var(--space-m);
}

.padding-bottom-l {
  padding-bottom: var(--space-l);
}

.padding-left-xs {
  padding-left: var(--space-xs);
}

.padding-left-s {
  padding-left: var(--space-s);
}

.padding-left-sm {
  padding-left: var(--space-sm);
}

.padding-left-m {
  padding-left: var(--space-m);
}

.padding-left-l {
  padding-left: var(--space-l);
}

.padding-xs {
  padding: var(--space-xs);
}

.padding-s {
  padding: var(--space-s);
}

.padding-sm {
  padding: var(--space-sm);
}

.padding-m {
  padding: var(--space-m);
}

.padding-l {
  padding: var(--space-l);
}
