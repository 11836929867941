/******************Spacing Variables**************************/
:root {
  --space-2xs: 3px;
  --space-xs: 6px;
  --space-s: 9px;
  --space-m: 12px;
  --space-l: 15px;
  --space-xl: 18px;
  --space-2xl: 21px;
  --space-3xl: 24px;
  --space-4xl: 48px;
  --space-5xl: 60px;
}
/***********************End Spacing Variables********************/
