.content-wrapper {
  position: relative;
  width: 100%;
  height: auto;
}

.inner-content {
  margin: 0 auto;
  max-width: 1000px;
  padding: 0 var(--space-m) var(--space-4xl) var(--space-m);
}

:root {
  font-size: 1.125rem !important;
}
