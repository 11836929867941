/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import '~@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';

@import './theme/spacingVariables.scss';
@import './theme/colors';
@import './theme/font';
@import './theme/common';
@import './theme/ionicFixes';
@import './theme/margins-paddings';
@import './theme/structure';

* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.toast-error {
  font-size: 18px;
}

#searchbar {
  .searchbar-input {
    border: 1px solid var(--color-light-2);
    font-size: 20px;
    color: var(--color-primary);
    --webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .searchbar-search-icon {
    top: 12px !important;
  }
}
.loading-spinner {
  --spinner-color: grey;
}
.error-modal {
  --height: 180px;
  .modal-wrapper {
    width: 50vw;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
  --border-radius: 4px;
}

.native-input[disabled].sc-ion-input-md {
  opacity: 1;
}
